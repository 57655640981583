<template>
  <div :class="['container', randomStyle[0]]">
    <div class="info">
      <img class="avatar" alt="avatar" :src="avatar" />
      <div :class="['nickname', randomStyle[1]]">{{ nickname }}</div>
      <div :class="randomStyle[2]" />
    </div>
    <div class="main" ref="main">
      <div class="item" v-for="sentence of sentences" :key="sentence">
        #{{ sentence }}
      </div>
    </div>
    <img
      class="logo"
      src="https://assets.cumen.fun/upload/017cdaf1-c5fa-788e-eea2-d0ee249f902b/0183d022-46e2-859f-2adb-b24bb64280ea?x-oss-process=style/origin"
    />
  </div>
</template>

<script>
import { arrayTypeAnnotation } from '@babel/types';

const randomStyles = [
  ["bg-yellow", "wall-blue", "symbol-blue"],
  ["bg-green", "wall-pink", "symbol-pink"],
  ["bg-blue", "wall-yellow", "symbol-yellow"],
  ["bg-pink", "wall-green", "symbol-green"],
];
export default {
  name: "profile",
  data() {
    return {
      userInfo: {},
      sentences: [],
      randomStyle: [],
    };
  },
  created() {
    let { nickname, avatar, props } = this.$route.query;
    this.nickname = nickname;
    this.avatar = avatar;
    this.sentences = this.shuffle(JSON.parse(props || "[]")).filter(content => !!content);
    this.randomStyle =
      randomStyles[Math.floor(Math.random() * randomStyles.length)];
  },
  mounted() {
    this.randomLabelStyle();
  },
  methods: {
    /** 获取标签的样式组 */
    randomLabelStyle() {
      const { sentences } = this;
      const main = this.$refs.main;
      let labels = main.querySelectorAll(".item");
      let styles = [];
      for (let i = 0; i < sentences.length; i++) {
        const style = this.generateStyle(i, styles[i - 1]);
        styles.push(style);
        labels[i].style.fontSize = style.fontSize;
        labels[i].style.textAlign = style.textAlign;
        labels[i].style.textIndent = style.textIndent;
      }
    },
    /** 生成单个标签的样式组 */
    generateStyle(i, pre) {
      const multipleCount = 1.7857;
      const { sentences } = this;
      const minFontSize = 20;
      const maxFontSize = 32;
      const maxFontSizeLong = 24;
      const alignArray = ["left", "center", "right"];
      const max = sentences[i].length >= 8 ? maxFontSizeLong : maxFontSize;
      const fontSize =
        ((Math.random() * (max - minFontSize + 1) + minFontSize) *
          multipleCount) /
          75 +
        "rem";
      const textAlign =
        alignArray[Math.floor(Math.random() * alignArray.length)];
      let textIndent = "0em";
      if (textAlign === "left") {
        const textIndents = [0, 1, 2];
        textIndent =
          textIndents[Math.floor(Math.random() * textIndents.length)] + "em";
      }
      /** 相邻两个项目的位置不能一致，这样体验更好 */
      if (pre && pre.textAlign === textAlign) return this.generateStyle(i, pre);
      return {
        fontSize,
        textAlign,
        textIndent,
      };
    },
    /** 洗牌 */ 
    shuffle(array) {
      let res = [], random;
      while (array.length > 0) {
        random = Math.floor(Math.random() * array.length)
        res.push(array[random]);
        array.splice(random, 1);
      }
      return res;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/global.scss";
$multipleCount: 1.7857;

.container {
  position: relative;
  width: 100%;
  height: 80vw;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  overflow: hidden;

  > .info {
    $avatarWidth: px2rem(56 * $multipleCount);

    @include row;
    width: calc(100% - px2rem(24 * $multipleCount * 2));
    margin: px2rem(16 * $multipleCount) px2rem(24 * $multipleCount) 0;
    height: $avatarWidth;

    > .avatar {
      width: $avatarWidth;
      height: $avatarWidth;
      border: px2rem(2 * $multipleCount) solid #000;
      border-radius: 50%;
      box-sizing: border-box;
    }

    > .nickname {
      position: relative;
      max-width: px2rem(238 * $multipleCount);
      height: $avatarWidth;
      margin-left: px2rem(12 * $multipleCount);
      font-size: px2rem(30 * $multipleCount);
      font-family: "Alibaba PuHuiTi Heavy", Alibaba PuHuiTi;
      white-space: nowrap;
      overflow: hidden;
      background: url(../../assets/images/wall-blue.png) no-repeat center bottom /
        contain;
    }

    > .wall-pink {
      background-image: url(../../assets/images/wall-pink.png);
    }
    > .wall-yellow {
      background-image: url(../../assets/images/wall-yellow.png);
    }
    > .wall-blue {
      background-image: url(../../assets/images/wall-blue.png);
    }
    > .wall-green {
      background-image: url(../../assets/images/wall-green.png);
    }

    > [class^="symbol-"] {
      position: absolute;
      top: px2rem(28 * $multipleCount);
      right: px2rem(14 * $multipleCount);
      width: px2rem(72 * $multipleCount);
      height: px2rem(88 * $multipleCount);
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
    }

    > .symbol-pink {
      background-image: url(../../assets/images/symbol-pink.png);
    }
    > .symbol-yellow {
      background-image: url(../../assets/images/symbol-yellow.png);
    }
    > .symbol-blue {
      background-image: url(../../assets/images/symbol-blue.png);
    }
    > .symbol-green {
      background-image: url(../../assets/images/symbol-green.png);
    }
  }

  > .main {
    @include column;
    position: absolute;
    left: px2rem(32 * $multipleCount);
    top: px2rem(90 * $multipleCount);
    width: calc(100vw - px2rem(60 * $multipleCount));
    height: px2rem(188 * $multipleCount);
    overflow: hidden;

    > .item {
      flex: 1;
      width: 100%;
      font-size: px2rem(20 * $multipleCount);
      font-family: "Alibaba PuHuiTi Heavy", Alibaba PuHuiTi;
      white-space: nowrap;
      overflow: hidden;

      &:first-of-type {
        width: calc(100% - px2rem(60 * $multipleCount));
        margin-right: px2rem(60 * $multipleCount);
      }
    }
  }

  > .logo {
    position: absolute;
    right: px2rem(20 * $multipleCount);
    bottom: px2rem(6 * $multipleCount);
    width: px2rem(120 * $multipleCount);
  }
}

.bg-pink {
  background-image: url(../../assets/images/bg-pink.png);
}
.bg-yellow {
  background-image: url(../../assets/images/bg-yellow.png);
}
.bg-blue {
  background-image: url(../../assets/images/bg-blue.png);
}
.bg-green {
  background-image: url(../../assets/images/bg-green.png);
}
</style>
